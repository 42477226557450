import React, { useCallback, useState } from 'react'

import { Box } from '@mui/material'

import Button from '../../../../shared/components/Button/Button.component'
import { Chip } from '../../../../shared/components/Chip/Chip.component'
import UrlWidget from '../../../../shared/components/UrlWidget/UrlWidget.component'
import MiniTripDetails from '../../../SharedTrip/components/MiniTripDetails/MiniTripDetails.component'

import { getItemDetails } from '../../../../redux/states/items/getItemDetails/getItemDetails.slice'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import Snackbar from '../../../../shared/components/Snackbar/Snackbar.functions'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import { isValidMarketplaceUrl } from './MarketplaceLinksInput.functions'
import styles from './MarketplaceLinksInput.styles'
import { MarketplaceLinksInputProps, MarketplaceUrlError } from './MarketplaceLinksInput.types'

export const MarketplaceLinksInput: React.FC<MarketplaceLinksInputProps> = (props) => {
  const { flightItinerary, isCompact = false } = props

  const [errors, setErrors] = useState<MarketplaceUrlError[]>([])
  const [urls, setUrls] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')

  const { loading } = useAppSelector((state) => state.getItemDetails)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  const handleErrorUpdate = useCallback(
    (newError: MarketplaceUrlError) => {
      const errorExists = errors.some((error) => error.url === newError.url)

      if (!errorExists) {
        setErrors((prevErrors) => [...prevErrors, newError])
      }
    },
    [errors]
  )

  const handleUrlValidation = useCallback(
    (url: string) => {
      return isValidMarketplaceUrl(url, handleErrorUpdate, flightItinerary)
    },
    [handleErrorUpdate, flightItinerary]
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      addUrl()
    }
  }

  const addUrl = () => {
    if (inputValue.trim()) {
      setUrls((prevUrls) => [...prevUrls, inputValue.trim()])
      setInputValue('')
    }
  }

  const removeUrl = (urlToRemove: string) => {
    setUrls((prevUrls) => prevUrls.filter((url) => url !== urlToRemove))
    setErrors((prevErrors) => prevErrors.filter((error) => error.url !== urlToRemove))
  }

  const onSubmit = () => {
    setErrors([])

    const validUrls = urls.filter((url) => handleUrlValidation(url))

    dispatch(
      getItemDetails({
        request: {
          urls: validUrls
        },

        onSuccess: (results) => {
          const { processedItems, unprocessableItems } = results
          const onlyUnprocessableItems = processedItems.length === 0 && unprocessableItems.length >= 0

          if (onlyUnprocessableItems) {
            Snackbar.showSnackbar({
              message: 'There was an issue processing your request. Please try again shortly.',
              severity: 'error'
            })
          } else {
            navigate(routes.cart.path, { state: { flightItineraryId: flightItinerary?.id } })
          }
        }
      })
    )
  }

  const isGetOrdersEnabled = urls.length > 0 && urls.some((url) => handleUrlValidation(url))

  const getMiniTripDetails = () => {
    if (flightItinerary) {
      return <MiniTripDetails flightItinerary={flightItinerary} />
    }
  }

  const getMarketplaceLinksInput = () => {
    return (
      <UrlWidget
        urls={urls}
        handleInputChange={handleInputChange}
        handleInputKeyPress={handleInputKeyPress}
        removeUrl={removeUrl}
        inputValue={inputValue}
        isValidMarketplaceUrl={handleUrlValidation}
        isCompact={isCompact}
      />
    )
  }

  const getErrorList = () => {
    if (errors.length > 0) {
      return (
        <Box sx={styles.errorListContainer}>
          {errors.map((error, index) => {
            return <Chip key={index} error={{ text: error.error.message, maxLength: 60 }} showCloseIcon={false} />
          })}
        </Box>
      )
    }
  }

  const getButton = () => {
    return (
      <Button
        text={isCompact ? 'Add Item' : 'Get Orders'}
        buttonType="primary"
        color="primary"
        disabled={!isGetOrdersEnabled}
        onClick={onSubmit}
        loading={loading}
        large={!isCompact}
        style={isCompact ? styles.compactButton : styles.largeButton}
      />
    )
  }

  const content = () => {
    if (isCompact) {
      return (
        <Box sx={styles.compactContainer}>
          <Box sx={styles.searchAreaContainer}>
            {getMarketplaceLinksInput()}
            {getButton()}
          </Box>

          {/* {getErrorList()} */}
        </Box>
      )
    } else {
      return (
        <Box sx={styles.largeWidgetContainer}>
          {getMiniTripDetails()}
          {getMarketplaceLinksInput()}

          <Box sx={styles.buttonContainer(errors.length > 0)}>
            {getErrorList()}
            {getButton()}
          </Box>
        </Box>
      )
    }
  }

  return content()
}
