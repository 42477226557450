import { CSSProperties } from 'react'

import { SxProps } from '@mui/material'

import theme from '../../../../shared/styles/themes/default.theme'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius / 4,
    border: '1px solid transparent',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[5],
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.dark}`
    }
  } as SxProps,

  cardTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  } as SxProps,

  title: {
    lineHeight: '24px',
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightMedium
  } as SxProps,

  editFlightButton: {
    minWidth: '32px',
    width: '32px',
    height: '32px',
    padding: 0,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    color: theme.palette.common.black
  },

  divider: {
    margin: theme.spacing(2, 0)
  } as SxProps,

  sectionContent: {
    paddingRight: theme.spacing(2)
  } as SxProps,

  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: theme.spacing(2)
  } as SxProps,

  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800]
  } as SxProps,

  amountTopContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  } as SxProps,

  subTotalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap'
  } as SxProps,

  timeSpan: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
    marginBottom: theme.spacing(0)
  } as CSSProperties,

  subtotalTitleText: {
    textAlign: 'left',
    marginRight: theme.spacing(2)
  } as SxProps,

  subtotalValue: {
    color: theme.palette.grey[500],
    textAlign: 'left'
  } as SxProps,

  segmentValueText: {
    color: theme.palette.grey[500],
    textAlign: 'left'
  } as SxProps,

  subTextBold: {
    color: theme.palette.grey[500],
    marginBottom: theme.spacing(1)
  } as SxProps,

  gap: {
    marginBottom: theme.spacing(1)
  } as SxProps,

  logo: {
    width: '40px',
    height: '40px',
    marginTop: theme.spacing(1)
  },

  amountToPayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    flexWrap: 'wrap'
  } as SxProps,

  amountToPayTitle: {
    textAlign: 'left',
    marginRight: theme.spacing(2)
  } as SxProps,

  amount: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  } as SxProps
}

export default styles
