import { useEffect, useState } from 'react'

import { ShareOutlined } from '@mui/icons-material'
import { Box, Divider, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import { EmptyState } from '../../../../shared/components/EmptyState/EmptyState.component'
import ShareFlightItineraryLinkModal from '../../../../shared/components/ShareFlightItineraryLinkModal/ShareFlightItineraryLink.component'
import ItemRequestAccordion from '../ItemRequestAccordion/ItemRequestAccordion.component'

import {
  FlightItinerary,
  Leg
} from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { getFlightItineraryShareLink } from '../../../../redux/states/flights/getFlightItineraryShareLink/getFlightItineraryShareLink.slice'
import { GetFlightItineraryShareLinkRequest } from '../../../../redux/states/flights/getFlightItineraryShareLink/getFlightItineraryShareLink.types'
import { RootState } from '../../../../redux/store/store.types'
import { ButtonProps } from '../../../../shared/components/Button/Button.types'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import status from '../../../../shared/dictionaries/status.dictionaries'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux.hooks'
import { getFormattedTripDetails } from '../../Requests.functions'
import styles from './FlightItineraryAccordion.styles'

const FlightItineraryAccordion = (props: PaginatedRenderProps) => {
  const flightItinerary = props.item as FlightItinerary
  const index = props.index
  const listLength = props.listLength

  const [updatedFlightItinerary, setUpdatedFlightItinerary] = useState<FlightItinerary>(flightItinerary)
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false)
  const [loadingButton, setLoadingButton] = useState<string | null>(null)
  const [isFirstAccordionExpanded, setIsFirstAccordionExpanded] = useState(index === 0 && listLength === 1)

  const { success: getFlightItineraryShareLinkSuccess, loading: getFlightItineraryShareLinkLoading } = useAppSelector(
    (state: RootState) => state.getFlightItineraryShareLink
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (index === 0 && listLength !== 1) {
      setIsFirstAccordionExpanded(false)
    }
  }, [index, listLength])

  useEffect(() => {
    setUpdatedFlightItinerary(flightItinerary)
  }, [flightItinerary])

  const getShareLink = () => {
    setLoadingButton(updatedFlightItinerary.id.toString())

    const request: GetFlightItineraryShareLinkRequest = {
      id: updatedFlightItinerary.id.toString()
    }

    const onSuccess = () => {
      setShareLinkModalOpen(true)
      setLoadingButton(null)
    }

    dispatch(getFlightItineraryShareLink({ request, onSuccess }))
  }

  const hasOfferRequestDetails = updatedFlightItinerary.itinerary.legs.some(
    (leg) => leg.offerRequestsDetails && leg.offerRequestsDetails.length > 0
  )

  const getAccordionHeader = () => {
    const tripDetails = getFormattedTripDetails(updatedFlightItinerary)

    const isBookFlightDisabled = updatedFlightItinerary.itinerary.legs.some((leg) =>
      leg.offerRequestsDetails.some((offerRequest) =>
        offerRequest.itemRequestDetails.some(
          (detail) =>
            detail.status.name === status.itemRequestStatus.pendingAcceptanceByTraveler ||
            detail.status.name === status.itemRequestStatus.reassignedByShopper
        )
      )
    )

    const accordionButtons: ButtonProps[] = [
      {
        text: 'Share Link',
        onClick: getShareLink,
        buttonType: 'secondary',
        loading: loadingButton === updatedFlightItinerary.id.toString() && getFlightItineraryShareLinkLoading,
        icon: <ShareOutlined />
      },
      {
        text: 'Book Flight',
        onClick: () => {
          // TODO: Book Flight
        },
        buttonType: 'primary',
        disabled: isBookFlightDisabled,
        tooltipText: isBookFlightDisabled
          ? 'Must action all item requests before you can book the flight.'
          : 'Must book flight before you can buy the items on Amazon.'
      }
    ]

    const accordionHeader = {
      mainContent: (
        <Typography variant="h6" sx={styles.accordionHeaderMainContent}>
          {tripDetails}
        </Typography>
      ),
      buttons: accordionButtons
    }

    return accordionHeader
  }

  const renderFlightLegHeader = (leg: Leg) => (
    <Box key={leg.id} style={styles.legHeader} my={2}>
      <Typography sx={styles.legHeaderTitle}>
        {`${leg.origin.airport.iataCode} → ${leg.destination.airport.iataCode}`}
      </Typography>
    </Box>
  )

  const renderFlightLegDetails = (leg: Leg) => (
    <Box key={leg.id} sx={styles.legDetails}>
      {renderFlightLegHeader(leg)}
      <ItemRequestAccordion flightLeg={leg} flightItineraryStatus={updatedFlightItinerary.status.name} />
      {updatedFlightItinerary.itinerary.legs.indexOf(leg) !== updatedFlightItinerary.itinerary.legs.length - 1 && (
        <Divider sx={styles.divider} />
      )}
    </Box>
  )

  const renderDetails = () => {
    if (!hasOfferRequestDetails) {
      return (
        <Box style={styles.emptyStateContainer}>
          <EmptyState
            title={'No Offers Available'}
            subtitle={
              'Share your flight itinerary to your friends and receive offers and earn discounts towards your flight.'
            }
            button={{
              text: 'Share Link',
              icon: <ShareOutlined />,
              loading: loadingButton === updatedFlightItinerary.id.toString() && getFlightItineraryShareLinkLoading,
              onClick: getShareLink
            }}
          />
        </Box>
      )
    } else {
      return <Box>{updatedFlightItinerary.itinerary.legs.map(renderFlightLegDetails)}</Box>
    }
  }

  return (
    <>
      <Accordion header={getAccordionHeader()} body={renderDetails()} isExpanded={isFirstAccordionExpanded} />

      <ShareFlightItineraryLinkModal
        shareLink={getFlightItineraryShareLinkSuccess?.data?.shareLink ?? ''}
        open={shareLinkModalOpen}
        onClose={() => setShareLinkModalOpen(false)}
      />
    </>
  )
}

export default FlightItineraryAccordion
