import React from 'react'

import { Box, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  XIcon
} from 'react-share'

import Button from '../Button/Button.component'
import Modal from '../Modal/Modal.component'

import images from '../../../assets/images'
import styles from './ShareFlightItineraryLink.styles'
import { ShareFlightItineraryLinkProps } from './ShareFlightItineraryLink.types'

const ShareFlightItineraryLink: React.FC<ShareFlightItineraryLinkProps> = (props) => {
  const { shareLink, open, onClose } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const modalBody = (
    <Box sx={styles.modalBody}>
      <Box sx={styles.shareLinkContainer}>
        <Typography variant="body1" sx={styles.shareViaSocialMedia}>
          Share via social media by clicking on the icon below.
        </Typography>

        <Box sx={styles.socialIconsContainer}>
          <FacebookShareButton url={shareLink} style={styles.socialIconButton}>
            <FacebookIcon size={48} round={true} />
          </FacebookShareButton>

          {isMobile && (
            <IconButton
              sx={styles.socialIconButton}
              href={`instagram://share?text=${encodeURIComponent(shareLink)}`}
              target="_blank"
            >
              <img src={images.instagramGraphic} style={styles.socialMediaIcon} alt="Instagram" />
            </IconButton>
          )}

          <TwitterShareButton url={shareLink} style={styles.socialIconButton}>
            <XIcon size={48} round={true} />
          </TwitterShareButton>

          <IconButton
            sx={styles.socialIconButton}
            href={`https://wa.me/?text=${encodeURIComponent(shareLink)}`}
            target="_blank"
          >
            <WhatsappIcon size={48} round={true} />
          </IconButton>

          <RedditShareButton url={shareLink} style={styles.socialIconButton}>
            <RedditIcon size={48} round={true} />
          </RedditShareButton>

          <TelegramShareButton url={shareLink} style={styles.socialIconButton}>
            <TelegramIcon size={48} round={true} />
          </TelegramShareButton>
        </Box>
      </Box>

      <Box sx={styles.copyLinkContainer}>
        <Typography sx={styles.copyLinkInstructionsText}>Copy the link to share on your preferred platform.</Typography>

        <Box sx={styles.copyLinkContentContainer}>
          <TextField sx={styles.copyLinkText} value={shareLink} InputProps={{ readOnly: true }} />

          <Button
            onClick={() => navigator.clipboard.writeText(shareLink || '')}
            buttonType="primary"
            text="Copy Link"
          />
        </Box>
      </Box>
    </Box>
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Get your trip discounted!"
      subtitle="Share Hourrier link so you can get paid to carry items."
      body={modalBody}
      primaryButton={{
        label: 'Close',
        onClick: onClose
      }}
    />
  )
}

export default ShareFlightItineraryLink
