import React, { useCallback, useState } from 'react'

import { Box, Typography } from '@mui/material'

import AcceptOffer from '../../../../pages/Offers/components/AcceptOffer/AcceptOffer.component'
import Avatar from '../../Avatar/Avatar.component'
import Button from '../../Button/Button.component'

import { validateOfferAcceptance } from '../../../../pages/Offers/components/OfferItem/OfferItem.functions'
import { FlightItinerary } from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { acceptPublicOffer } from '../../../../redux/states/offerRequest/acceptPublicOffer/acceptPublicOffer.slice'
import { OfferRequest } from '../../../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import { RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import Modal from '../../../components/Modal/Modal.functions'
import Snackbar from '../../../components/Snackbar/Snackbar.functions'
import date, { dateTimeFormats } from '../../../functions/Date/date.functions'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks'
import styles from './OfferDealItem.styles'
import { OfferDealItemProps } from './OfferDealItem.types'

const OfferDealItem: React.FC<OfferDealItemProps> = ({ deal, flightItineraries }) => {
  const [offerAccepted, setOfferAccepted] = useState(false)
  const [loadingOfferId, setLoadingOfferId] = useState<number | null>(null)

  const { loading: acceptPublicOfferLoading } = useAppSelector((state: RootState) => state.acceptPublicOffer)

  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  const onConfirm = useCallback(
    (offer: OfferRequest, flightItinerary: FlightItinerary, onOfferAccepted?: () => void) => {
      setLoadingOfferId(offer.offerRequestId)
      dispatch(
        acceptPublicOffer({
          acceptPublicOfferRequest: {
            offerRequestId: offer.offerRequestId,
            confirmationFlightItineraryId: flightItinerary.id,
            isConfirming: true
          },
          onSuccess: () => {
            Snackbar.showSnackbar({
              message: 'Offer successfully accepted! Go to the Requests page to see your accepted offers.',
              action: {
                label: 'Go to Requests',
                onClick: () => {
                  navigate(routes.requests.path)
                  window.scrollTo(0, 0)
                }
              },
              severity: 'success'
            })
            onOfferAccepted?.()
            setLoadingOfferId(null)
            setOfferAccepted(true)
          }
        })
      )
    },
    [navigate, dispatch]
  )

  const onAccepOfferValidation = useCallback(() => {
    const items = deal.offerRequestDetails.map((item) => item.itemDetails)
    const deliveryLocation = deal.shopperDetails.deliveryTo
    const validation = validateOfferAcceptance(flightItineraries, items, deliveryLocation, deal)
    if (validation.noMatchingFlightItineraries) {
      const neededByDate = deal.neededByDate ? `by ${date(deal.neededByDate).format(dateTimeFormats.date.medium)}` : ''
      // Show "Book Flight" confirmation modal
      Modal.show({
        title: 'Book Flight',
        subtitle: `You are required to have a flight leg between ${validation.requiredCountries.itemLocation.name} and ${validation.requiredCountries.deliveryLocation.name} ${neededByDate} to accept this offer.`,
        primaryButton: {
          label: 'Book Flight',
          onClick: () => {
            navigate(routes.flightSearchResults.path, {
              state: {
                requiredCountries: validation.requiredCountries,
                selectedOffer: deal
              }
            })
          }
        },
        secondaryButton: {
          label: 'Cancel'
        }
      })
    } else if (!validation.noMatchingFlightItineraries && !validation.multipleOptions) {
      Modal.show({
        title: 'Select Offer',
        subtitle: `Are you sure you want to select this offer?`,
        primaryButton: {
          label: 'Confirm',
          onClick: () => {
            onConfirm(deal, validation.flightItineraries[0])
          }
        },
        secondaryButton: {
          label: 'Close'
        }
      })
    } else if (validation.multipleOptions) {
      // Show "Choose Flight" confirmation modal (if multiple options)
      let selectedFlightItineraryId: number | null = null

      const handleConfirm = () => {
        if (selectedFlightItineraryId !== null) {
          const flightItinerary = validation.flightItineraries?.find(
            (flightItinerary) => flightItinerary.id === selectedFlightItineraryId
          )

          if (flightItinerary) {
            onConfirm(deal, flightItinerary)
          } else {
            Snackbar.showSnackbar({
              message: 'Please select a valid flight booking to select the offer.',
              severity: 'error'
            })
          }
        }
      }

      Modal.show({
        title: 'Choose Flight',
        subtitle: `You have multiple flight bookings available. Please choose one to select the offer.`,
        body: (
          <AcceptOffer
            response={validation}
            onConfirm={(flightItineraryId) => {
              selectedFlightItineraryId = flightItineraryId
            }}
          />
        ),
        primaryButton: {
          label: 'Confirm',
          onClick: handleConfirm
        },
        secondaryButton: {
          label: 'Close'
        }
      })
    }

    return validation
  }, [deal, navigate, flightItineraries, onConfirm])

  return (
    <Box sx={styles.container}>
      <Box sx={styles.userInfoPill}>
        <Avatar firstName={deal.shopperDetails.firstName} lastName={deal.shopperDetails.lastName} />

        <Box>
          <Typography variant="body1" sx={styles.userName}>
            {deal.shopperDetails.firstName} {deal.shopperDetails.lastName.charAt(0)}
          </Typography>

          <Typography variant="body2" sx={styles.location}>
            {`${deal.shopperDetails.deliveryTo.city}, ${deal.shopperDetails.deliveryTo.country}`}
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.imageContainer}>
        <img
          src={deal.offerRequestDetails[0].itemDetails.images[0]}
          alt={deal.shopperDetails.firstName}
          style={styles.image}
        />
      </Box>

      <Box sx={styles.spacer} />

      <Box sx={styles.carrigeInfoContainer}>
        <Typography variant="body1" sx={styles.itemsCount}>
          Carry {deal.offerRequestDetails.length} item{deal.offerRequestDetails.length > 1 ? 's' : ''}
        </Typography>

        <Typography variant="body2" sx={styles.discount}>
          ${+deal.offerRequestDetails[0].itemDetails.price} off Flight
        </Typography>
      </Box>

      <Box sx={styles.buttonContainer}>
        <Button
          sx={styles.bookButton}
          text={offerAccepted ? 'Offer Accepted' : 'Book now'}
          fullWidth
          variant={'outlined'}
          buttonType={'primary'}
          onClick={onAccepOfferValidation}
          loading={acceptPublicOfferLoading && loadingOfferId === deal.offerRequestId}
          disabled={offerAccepted}
        />
      </Box>
    </Box>
  )
}

export default OfferDealItem
