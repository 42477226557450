import { useCallback, useEffect, useMemo, useState } from 'react'

import { ArrowForwardRounded, ExpandMore } from '@mui/icons-material'
import { Box, Divider, StepLabel as MuiStepLabel, Step, Stepper, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Link from '../../../../shared/components/Link/Link.component'
// import Stepper from '../../../../shared/components/Stepper/Stepper.component'
import StepLabel from '../StepLabel/StepLabel.component'

import {
  FlightDetails,
  FlightItinerary
} from '../../../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { RootState } from '../../../../redux/store/store.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { AccordionButtonProps, AccordionFooterProps } from '../../../../shared/components/Accordion/Accordion.types'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import status from '../../../../shared/dictionaries/status.dictionaries'
import date, { dateTimeFormats, formatDuration } from '../../../../shared/functions/Date/date.functions'
import { joinStringWithBullet, joinStringWithDash } from '../../../../shared/functions/String/string.functions'
import { useAppSelector } from '../../../../shared/hooks/redux.hooks'
import { getFormattedCabinClass, getFormattedTravelerDateDetails, getFormattedTripDetails } from '../../Trips.functions'
import styles from './TripDetailsAccordion.styles'

const TripDetailsAccordion = (props: PaginatedRenderProps) => {
  const flightItinerary = props.item as FlightItinerary
  const index = props.index
  const listLength = props.listLength
  const isCompletedTrip = date(
    flightItinerary.itinerary.legs[flightItinerary.itinerary.legs.length - 1].travelDate
  ).isBefore(date().currentDate)

  const [updatedFlightItinerary, setUpdatedFlightItinerary] = useState<FlightItinerary>(flightItinerary)
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({})
  const [isFirstAccordionExpanded, setIsFirstAccordionExpanded] = useState(
    index === 0 && listLength === 1 && !isCompletedTrip
  )

  const { success: getAllFlightItinerariesSuccess } = useAppSelector(
    (state: RootState) => state.getAllFlightItineraries
  )

  const navigate = router.navigate()

  useEffect(() => {
    if (index === 0 && listLength !== 1) {
      setIsFirstAccordionExpanded(false)
    }
  }, [index, listLength])

  const refreshFlightItineraries = useCallback(() => {
    const relatedFlightItinerary = getAllFlightItinerariesSuccess?.data.results.find(
      (_flightItinerary) => _flightItinerary.id === flightItinerary.id
    )

    setUpdatedFlightItinerary(relatedFlightItinerary ?? flightItinerary)
  }, [getAllFlightItinerariesSuccess, flightItinerary])

  useEffect(() => {
    if (getAllFlightItinerariesSuccess) {
      refreshFlightItineraries()
    }
  }, [getAllFlightItinerariesSuccess, refreshFlightItineraries])

  const handleAccordionExpansion = (isExpanded: boolean) => {
    setExpanded((prev) => ({
      ...prev,
      [flightItinerary.id]: isExpanded
    }))
  }

  const getAccordionHeader = () => {
    const locationsDetails = getFormattedTripDetails(flightItinerary)
    const travelerDateDetails = getFormattedTravelerDateDetails(flightItinerary)
    const tripType = flightItinerary.itinerary.tripType
    const formattedTripType = tripType === 'roundTrip' ? 'Round Trip' : tripType === 'oneWay' ? 'One Way' : 'Multi-City'
    const tripPrice = parseFloat(updatedFlightItinerary.itinerary.totalFlightPrice).toFixed(2)

    const accordionHeader = {
      mainContent: (
        <Box sx={styles.accordionHeaderMainContent}>
          <Box sx={styles.accordionHeaderMainContentTitleContainer}>
            <Typography sx={styles.accordionHeaderMainContentTitleText}>{locationsDetails}</Typography>

            <Box sx={styles.tripTypeContainer}>
              <Typography sx={styles.tripTypeText}>{formattedTripType}</Typography>
            </Box>
          </Box>

          <Typography sx={styles.accordionHeaderMainContentSubtitleText}>{travelerDateDetails}</Typography>
        </Box>
      ),
      secondaryContent: (
        <Box sx={styles.accordionHeaderSecondaryContent}>
          <Typography sx={styles.accordionHeaderSecondaryContentTitleText}>${tripPrice}</Typography>
          <Typography sx={styles.accordionHeaderSecondaryContentSubtitleText}>Trip Cost</Typography>
        </Box>
      )
    }

    return accordionHeader
  }

  const preventExpandChange = useMemo(() => {
    return flightItinerary.itinerary.associatedFlights.length === 0
  }, [flightItinerary.itinerary.associatedFlights])

  const renderAssociatedFlight = (associatedFlightDetails: FlightDetails) => {
    const steps = [
      {
        label: `${date(associatedFlightDetails.departureTime).format(`${dateTimeFormats.date.long} ${dateTimeFormats.time.short}`)}`,
        description: `Depart from ${associatedFlightDetails.origin.airport.name} (${associatedFlightDetails.origin.airport.iataCode})`
      },
      {
        label: `${date(associatedFlightDetails.arrivalTime).format(`${dateTimeFormats.date.long} ${dateTimeFormats.time.short}`)}`,
        description: `Arrive at ${associatedFlightDetails.destination.airport.name} (${associatedFlightDetails.destination.airport.iataCode})`
      }
    ]

    const additionalDetails = [
      {
        label: 'Type',
        description: getFormattedCabinClass(updatedFlightItinerary.itinerary.cabinClass)
      },
      {
        label: 'Airline',
        description: associatedFlightDetails.airline.name
      },
      {
        label: 'Plane',
        description: associatedFlightDetails.aircraftName ?? 'N/A'
      },
      {
        label: 'Flight Number',
        description: associatedFlightDetails.flightNumber
      }
    ]

    const flightLegSummary = () => {
      const getDateSummary = () => {
        const departureDate = date(associatedFlightDetails.departureTime).format(dateTimeFormats.date.medium)
        const arrivalDate = date(associatedFlightDetails.arrivalTime).format(dateTimeFormats.date.medium)

        if (departureDate === arrivalDate) {
          return joinStringWithBullet([
            `${date(associatedFlightDetails.departureTime).format(dateTimeFormats.date.long)}`,
            joinStringWithDash([
              `${date(associatedFlightDetails.departureTime).format(dateTimeFormats.time.short)}`,
              `${date(associatedFlightDetails.arrivalTime).format(dateTimeFormats.time.short)}`
            ])
          ])
        } else {
          const departureTime = joinStringWithBullet([
            `${date(associatedFlightDetails.departureTime).format(dateTimeFormats.date.long)}`,
            `${date(associatedFlightDetails.departureTime).format(dateTimeFormats.time.short)}`
          ])

          const arrivalTime = joinStringWithBullet([
            `${date(associatedFlightDetails.arrivalTime).format(dateTimeFormats.date.long)}`,
            `${date(associatedFlightDetails.arrivalTime).format(dateTimeFormats.time.short)}`
          ])

          return joinStringWithDash([departureTime, arrivalTime])
        }
      }

      const tripDuration = formatDuration(associatedFlightDetails.duration)

      const tripRoute = `${associatedFlightDetails.origin.airport.iataCode} → ${associatedFlightDetails.destination.airport.iataCode}`

      return (
        <Box sx={styles.legSummaryContainer}>
          <Box sx={styles.airlineLogoContainer}>
            <img
              src={associatedFlightDetails.airline.logo}
              alt={associatedFlightDetails.airline.name}
              style={styles.airlineLogo}
            />
          </Box>

          <Box sx={styles.legSummaryContentContainer}>
            <Box sx={styles.legSummaryContent}>
              <Typography sx={styles.legSummaryContentTitleText}>{getDateSummary()}</Typography>
              <Typography sx={styles.legSummaryContentSubtitleText}>{associatedFlightDetails.airline.name}</Typography>
            </Box>

            <Box sx={styles.legSummaryContent}>
              <Typography sx={styles.legSummaryContentTitleText}>{tripDuration}</Typography>
              <Typography sx={styles.legSummaryContentSubtitleText}>{tripRoute}</Typography>
            </Box>
          </Box>
        </Box>
      )
    }

    const flightLegSteps = () => {
      return (
        <Box sx={styles.legDetails}>
          {/* <Stepper activeStep={-1} orientation="vertical" steps={steps} noBackground /> */}
          <Stepper activeStep={-1} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <MuiStepLabel
                  StepIconComponent={StepLabel}
                  optional={<Typography sx={styles.legStepDescriptionText}>{step.description}</Typography>}
                >
                  <Typography sx={styles.legStepLabelText}>{step.label}</Typography>
                </MuiStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )
    }

    const renderAdditionalFlightDetails = () => {
      return (
        <Box sx={styles.additionalDetailsContainer}>
          {additionalDetails.map((detail) => (
            <Box sx={styles.additionalDetailContainer}>
              <Typography sx={styles.additionalDetailLabelText}>{detail.label}</Typography>
              <Typography sx={styles.additionalDetailDescriptionText}>{detail.description}</Typography>
            </Box>
          ))}
        </Box>
      )
    }

    return (
      <Box sx={styles.legDetailsContainer}>
        {flightLegSummary()}
        {flightLegSteps()}
        {renderAdditionalFlightDetails()}
      </Box>
    )
  }

  const renderDetails = () => {
    // const validAssociatedFlight = flightItinerary.itinerary.associatedFlights.find(
    //   (associatedFlight) => associatedFlight.isValid
    // )

    const validAssociatedFlight = flightItinerary.itinerary.associatedFlights[0]

    if (!validAssociatedFlight) {
      return null
    } else {
      return (
        <Box sx={styles.flightDetailsContainer}>
          {validAssociatedFlight.flightDetails.map((associatedFlightDetails) => {
            const isLastAssociatedFlightLeg =
              validAssociatedFlight.flightDetails.indexOf(associatedFlightDetails) ===
              validAssociatedFlight.flightDetails.length - 1

            return (
              <Box>
                {renderAssociatedFlight(associatedFlightDetails)}
                {!isLastAssociatedFlightLeg && <Divider sx={styles.divider} />}
              </Box>
            )
          })}
        </Box>
      )
    }
  }

  const renderFooter = () => {
    const isBookFlightDisabled = updatedFlightItinerary.itinerary.legs.some((leg) =>
      leg.offerRequestsDetails.some((offerRequest) =>
        offerRequest.itemRequestDetails.some(
          (detail) =>
            detail.status.name === status.itemRequestStatus.pendingAcceptanceByTraveler ||
            detail.status.name === status.itemRequestStatus.reassignedByShopper
        )
      )
    )

    const isCancelTripDisabled = () => {
      const lastLeg = updatedFlightItinerary.itinerary.legs[updatedFlightItinerary.itinerary.legs.length - 1]

      let isTripInCancellableState =
        updatedFlightItinerary.status.name === status.flightStatus.interestedInFlight ||
        updatedFlightItinerary.status.name === status.flightStatus.flightBooked

      if (date().isAfter(lastLeg.travelDate)) {
        isTripInCancellableState = false
      }

      return !isTripInCancellableState
    }

    const handleDetailsButtonPress = (id: number) => {
      setExpanded((prev) => ({
        ...prev,
        [id]: !prev[id]
      }))
    }

    const accordionFooterButtons: AccordionButtonProps[] = [
      {
        text: 'Details',
        icon: (
          <ExpandMore
            style={{
              transform: expanded[flightItinerary.id] ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s'
            }}
          />
        ),
        disabled: preventExpandChange,
        iconPosition: 'end',
        onClick: () => handleDetailsButtonPress(flightItinerary.id),
        expandAccordion: true,
        buttonType: 'tertiary'
      },
      {
        text: 'Cancel Trip',
        onClick: () => {
          // TODO: Cancel Trip
        },
        buttonType: 'secondary',
        disabled: isCancelTripDisabled(),
        tooltipText: isCancelTripDisabled()
          ? 'Trip is not in a cancellable state.'
          : 'Cancelling trip will have the effect of cancelling all associated item requests.'
      },
      {
        text: 'Book Flight',
        onClick: () => {
          // TODO: Book Flight
        },
        buttonType: 'primary',
        disabled: isBookFlightDisabled,
        tooltipText: isBookFlightDisabled
          ? 'Must action all item requests before you can book the flight.'
          : 'Must book flight before you can buy the items on Amazon.'
      }
    ]

    const accordionFooterMainContent = () => {
      const numberOfOfferRequests = updatedFlightItinerary.itinerary.legs.reduce((acc, leg) => {
        leg.offerRequestsDetails.forEach((offerRequest) => {
          acc += offerRequest.itemRequestDetails.length
        })

        return acc
      }, 0)

      return (
        <Box sx={styles.accordionFooterMainContent}>
          <Typography sx={styles.accordionFooterMainContentText}>
            You have {numberOfOfferRequests} Requests
            <Link href={routes.requests.path} showColor style={styles.accordionFooterMainContentLink}>
              Go to Requests
            </Link>
          </Typography>

          <ArrowForwardRounded sx={styles.goToRequestsArrowIcon} onClick={() => navigate(routes.requests.path)} />
        </Box>
      )
    }

    const accordionFooter: AccordionFooterProps = {
      mainContent: accordionFooterMainContent(),
      buttons: accordionFooterButtons
    }

    return accordionFooter
  }

  return (
    <Accordion
      header={getAccordionHeader()}
      body={renderDetails()}
      preventExpandChange={preventExpandChange}
      isExpanded={isFirstAccordionExpanded}
      noExpandIcon
      handleExpansion={handleAccordionExpansion}
      footer={renderFooter()}
      key={flightItinerary.id}
    />
  )
}

export default TripDetailsAccordion
