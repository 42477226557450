import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import Button from '../../shared/components/Button/Button.component'
import ShareFlightItineraryLink from '../../shared/components/ShareFlightItineraryLink/ShareFlightItineraryLink.component'
import ShareLinkModal from '../../shared/components/ShareFlightItineraryLinkModal/ShareFlightItineraryLink.component'
import FlightSummaryCard from './components/FlightSummaryCard/FlightSummaryCard.component'
import TripOverview from './components/TripOverview/TripOverview.component'

import { isAuthenticated } from '../../networkRequests/apiClient/apiClient.functions'
import {
  clearCreateFlightItinerarySuccess,
  createFlightItinerary
} from '../../redux/states/flights/createFlightItinerary/createFlightItinerary.slice'
import {
  CreateFlightItinaryLeg,
  CreateFlightItineraryRequestPayload
} from '../../redux/states/flights/createFlightItinerary/createFlightItinerary.types'
import { FareOffer } from '../../redux/states/flights/getFlightFares/types/FareDetailsResponse.types'
import { Passenger } from '../../redux/states/flights/searchFlights/types/SearchFlightRequest.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import date, { dateTimeFormats, formatDuration, formatTime } from '../../shared/functions/Date/date.functions'
import localStorage from '../../shared/functions/LocalStorage/localStorage'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import { PassengerType } from '../../shared/types/flights.types'
import {
  CabinClass,
  FlightSearchInitialValues,
  cabinClassMapping
} from '../Travel/components/FlightSearchWidget/FlightSearchWidget.types'
import styles from './TripSummary.styles'
import { TripSummaryProps } from './TripSummary.types'

const TripSummary: React.FC<TripSummaryProps> = ({
  tripFare,
  selectedOffers,
  searchedValues: searchedValuesFromProps
}) => {
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false)
  const [loadingButton, setLoadingButton] = useState<string | null>(null)

  const fareOffer = useMemo(() => {
    return (router.getNavigationProps().state.fare as FareOffer) || tripFare
  }, [tripFare])

  const searchedValues = useMemo(() => {
    const searchValuesFromRouter = router.getNavigationProps().state.searchedValues as FlightSearchInitialValues

    return searchValuesFromRouter || searchedValuesFromProps
  }, [searchedValuesFromProps])

  const navigate = router.navigate()

  const { success: bookFlightSuccess, loading: bookFlightLoading } = useAppSelector(
    (state) => state.createFlightItinerary
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearCreateFlightItinerarySuccess())
    }
  }, [dispatch])

  useEffect(() => {
    if (!fareOffer) return
  }, [fareOffer])

  const onEditFlight = useCallback(() => {
    if (!selectedOffers) {
      window.history.back()
    }
  }, [selectedOffers])

  const getNumberOfStops = (offer: FareOffer) => {
    return offer.slices.map((slice) => slice.segments.length - 1).reduce((acc, curr) => acc + curr, 0)
  }

  const createItinerary = useCallback(
    (onSuccess: () => void) => {
      const passengers: Passenger[] = []

      for (let i = 0; i < searchedValues.adults; i++) {
        passengers.push({ type: 'adult' })
      }

      for (let i = 0; i < searchedValues.children; i++) {
        passengers.push({ age: searchedValues.childrenAges[i] })
      }
      let legs: CreateFlightItinaryLeg[] = []

      if (searchedValues.tripType === 'roundTrip') {
        legs.push({
          travelDate: searchedValues.flights[0].departure as string,
          originAirportIataCode: fareOffer.slices[0].origin.iata_code,
          destinationAirportIataCode: fareOffer.slices[0].destination.iata_code
        })

        legs.push({
          travelDate: searchedValues.flights[0].return as string,
          originAirportIataCode: fareOffer.slices[1].origin.iata_code,
          destinationAirportIataCode: fareOffer.slices[1].destination.iata_code
        })
      } else {
        legs = fareOffer?.slices.map((slice, index) => ({
          travelDate: searchedValues.flights[index].departure as string,
          originAirportIataCode: slice.origin.iata_code,
          destinationAirportIataCode: slice.destination.iata_code
        }))
      }
      const requestPayload: CreateFlightItineraryRequestPayload = {
        request: {
          cabinClass: Object.keys(cabinClassMapping).find(
            (key) => cabinClassMapping[key as CabinClass] === searchedValues.cabinClass
          ) as CabinClass,
          tripType: searchedValues.tripType,
          totalFlightPrice: Number(fareOffer.total_amount),
          currency: fareOffer?.total_currency,
          numberOfStops: getNumberOfStops(fareOffer),
          legs: legs.map((leg) => ({
            ...leg,
            travelDate: date(leg.travelDate).format(dateTimeFormats.date.api) as string
          })),
          passengers: passengers.map((passenger) => ({
            type: passenger.type as PassengerType,
            age: passenger.age
          })),
          externalOfferId: fareOffer.id
        },

        onSuccess: onSuccess
      }

      dispatch(createFlightItinerary(requestPayload))
    },
    [dispatch, searchedValues, fareOffer]
  )

  const onBookFlight = useCallback(() => {
    setLoadingButton('bookFlight')

    if (fareOffer?.id) {
      if (bookFlightSuccess?.data?.shareLink) {
        setShareLinkModalOpen(true)
        setLoadingButton(null)
      } else {
        createItinerary(() => {
          setShareLinkModalOpen(true)
          setLoadingButton(null)
        })
      }
    }
  }, [fareOffer?.id, bookFlightSuccess?.data?.shareLink, createItinerary, setShareLinkModalOpen])

  const onContinueToRequests = useCallback(() => {
    setLoadingButton('continueToRequests')

    if (bookFlightSuccess?.data?.shareLink) {
      navigate(routes.requests.path)
    } else {
      createItinerary(() => {
        navigate(routes.requests.path)
        setLoadingButton(null)
      })
    }
  }, [navigate, createItinerary, bookFlightSuccess?.data?.shareLink])

  const onSkipToPayment = useCallback(() => {
    setLoadingButton('skipToPayment')

    if (bookFlightSuccess?.data?.shareLink) {
      navigate(routes.requests.path)
    } else {
      createItinerary(() => {
        navigate(routes.requests.path)
        setLoadingButton(null)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, createItinerary])

  const onLogin = useCallback(() => {
    localStorage.setItem('fareOffer', fareOffer)
    localStorage.setItem('shouldRedirectToTripSummary', true)

    navigate(routes.login.path)
  }, [fareOffer, navigate])

  return (
    <Grid container sx={styles.gridContainer}>
      <Grid item xs={12} sm={9}>
        <Box sx={styles.container}>
          <Typography sx={styles.summaryTitle} variant="h5">
            Trip Summary
          </Typography>

          <Typography sx={styles.summarySubtitle} variant="body2">
            Prices are shown in US dollars and are per person inclusive of taxes and fees with the exception of baggage
            fees.
          </Typography>

          <Box sx={styles.tripDetailsContainer}>
            {fareOffer.slices.map((slice) => {
              return (
                <Box key={slice.id}>
                  <Typography variant="h6">
                    {slice.origin.iata_code} to {slice.destination.iata_code}
                  </Typography>

                  {slice.segments.map((segment) => (
                    <FlightSummaryCard
                      key={segment.id}
                      flightNumber={segment.operating_carrier_flight_number}
                      departingAt={date(segment.departing_at).format(dateTimeFormats.date.medium) ?? ''}
                      duration={formatDuration(segment.duration)}
                      airlineLogo={segment.operating_carrier.logo_symbol_url}
                      originAirportName={segment.origin.iata_code}
                      destinationAirportName={segment.destination.iata_code}
                      departureTime={formatTime(segment.departing_at)}
                      arrivalTime={formatTime(segment.arriving_at)}
                      travelClass={fareOffer.slices[0].fare_brand_name}
                    />
                  ))}
                </Box>
              )
            })}
          </Box>

          <ShareLinkModal
            shareLink={bookFlightSuccess?.data?.shareLink ?? ''}
            open={shareLinkModalOpen}
            onClose={() => setShareLinkModalOpen(false)}
          />
        </Box>
      </Grid>

      <Grid item xs={12} sm={3}>
        {!selectedOffers && (
          <ShareFlightItineraryLink
            onPress={onBookFlight}
            showButton={isAuthenticated()}
            loading={loadingButton === 'bookFlight' && bookFlightLoading}
          />
        )}

        <Box sx={styles.tripOverviewContainer}>
          <TripOverview onEditFlight={onEditFlight} offerData={fareOffer} />
        </Box>

        {isAuthenticated() ? (
          <>
            <Button
              text="Continue to Requests"
              fullWidth
              variant={'outlined'}
              buttonType="primary"
              loading={loadingButton === 'continueToRequests' && bookFlightLoading}
              onClick={onContinueToRequests}
            />

            <Button
              text="Skip to Payment"
              fullWidth
              variant={'text'}
              buttonType="primary"
              loading={loadingButton === 'skipToPayment' && bookFlightLoading}
              onClick={onSkipToPayment}
            />
          </>
        ) : (
          <Button text="Login to continue" fullWidth variant={'contained'} buttonType="primary" onClick={onLogin} />
        )}
      </Grid>
    </Grid>
  )
}

export default TripSummary
