import { FlightPlace } from '../../../../redux/states/flights/getFlightPlaces/getFlightPlaces.types'
import { Date } from '../../../../shared/functions/Date/date.types'
import { RequiredCountries } from '../../../Offers/components/OfferItem/OfferItem.types'

export interface SearchFlightConfig {
  origin: string
  destination: string
  neededByDate: string | null
  neededByDateISO: string
}

export interface FlightSearchWidgetProps {
  isCompact?: boolean
  searchFlightConfig?: SearchFlightConfig | null
  requiredCountries?: RequiredCountries
  setContracted?: (contracted: boolean) => void
  initialValues?: FlightSearchInitialValues
}

export interface FlightSearchInitialValues {
  tripType: TripType
  flights: InitialFlight[]
  cabinClass: CabinClassDisplayName
  adults: number
  children: number
  childrenAges: number[]
}

export type TripType = 'roundTrip' | 'oneWay' | 'multiCity'

export const cabinClassDisplayName = {
  Economy: 'Economy',
  PremiumEconomy: 'Economy Premium',
  Business: 'Business',
  First: 'First'
} as const

export type CabinClassDisplayName = (typeof cabinClassDisplayName)[keyof typeof cabinClassDisplayName]

export const cabinClassMapping = {
  economy: cabinClassDisplayName.Economy,
  premium_economy: cabinClassDisplayName.PremiumEconomy,
  business: cabinClassDisplayName.Business,
  first: cabinClassDisplayName.First
} as const

export type CabinClass = keyof typeof cabinClassMapping

export interface Flight {
  from: FlightPlace | null
  to: FlightPlace | null
  departure: Date | null
  return?: Date | null
}

export interface InitialFlight {
  from: FlightPlace | null
  to: FlightPlace | null
  departure: string | null
  return?: string | null
}
