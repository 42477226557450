import React, { useCallback, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import PaginatedPage from '../../shared/components/PaginatedPage/PaginatedPage.component'
import OfferItem from './components/OfferItem/OfferItem.component'

import { isAuthenticated } from '../../networkRequests/apiClient/apiClient.functions'
import { getAllFlightItineraries } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.slice'
import {
  FlightItinerary,
  GetAllFlightItinerariesRequest
} from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { getPublicOffers } from '../../redux/states/offerRequest/getPublicOffers/getPublicOffers.slice'
import {
  GetPublicOffersRequest,
  OfferRequest
} from '../../redux/states/offerRequest/getPublicOffers/getPublicOffers.types'
import { RootState } from '../../redux/store/store.types'
import { EmptyStateProps } from '../../shared/components/EmptyState/EmptyState.types'
import {
  PaginatedRenderProps,
  PaginatedResponse,
  SearchBarProps
} from '../../shared/components/PaginatedPage/PaginatedPage.types'
import {
  FilterSectionConfig,
  Filters
} from '../../shared/components/UniversalFilterSideBar/UniversalFilterSideBar.types'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import { convertToPounds } from './Offers.functions'
import styles from './Offers.styles'

const Offers: React.FC = () => {
  const { loading } = useAppSelector((state: RootState) => state.getPublicOffers)
  const [flightItineraries, setFlightItineraries] = useState<FlightItinerary[]>([])

  const dispatch = useAppDispatch()

  const fetchOffers = useCallback(
    async (page: number): Promise<PaginatedResponse<OfferRequest>> => {
      return new Promise((resolve) => {
        const getPublicOffersPayload: GetPublicOffersRequest = {
          page,
          size: 5,
          searchQuery: ''
        }

        dispatch(
          getPublicOffers({
            getPublicOffersRequest: getPublicOffersPayload,
            onSuccess: (response) => {
              resolve({
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                results: response.data.results
              })
            }
          })
        )
      })
    },
    [dispatch]
  )

  useEffect(() => {
    const fetchFlightItineraries = async () => {
      if (!isAuthenticated()) return
      const getAllFlightItinerariesPayload: GetAllFlightItinerariesRequest = {}

      const response = await dispatch(
        getAllFlightItineraries({
          request: getAllFlightItinerariesPayload
        })
      ).unwrap()

      setFlightItineraries(response.data.results)
    }

    fetchFlightItineraries()
  }, [dispatch])

  const OfferItemsView = function (props: PaginatedRenderProps) {
    const offer = props.item as OfferRequest

    return (
      <Box sx={styles.offerItemContainer}>
        <OfferItem offer={offer} flightItineraries={flightItineraries} />
      </Box>
    )
  }

  const searchBar: SearchBarProps = {
    placeholder: 'Type any item name',
    onSearchFilter: (items, query) => {
      const lowerQuery = query.toLowerCase()

      return (items as OfferRequest[]).filter((item) =>
        item.offerRequestDetails.some(
          (detail) =>
            detail.itemDetails.title.toLowerCase().includes(lowerQuery) ||
            detail.itemDetails.country.name.toLowerCase().includes(lowerQuery) ||
            detail.itemDetails.country.name.toLowerCase().includes(lowerQuery)
        )
      )
    }
  }

  const emptyState: EmptyStateProps = {
    title: 'No offers available',
    subtitle: 'Currently, there are no offers available. Please check back later.'
  }

  const filterSections: (items: unknown[], filters: Filters) => FilterSectionConfig[] = useCallback(
    (items: unknown[]) => {
      const offers = items as OfferRequest[]

      // Extract unique source and delivery country pairs
      const destinations = offers.flatMap((offer) =>
        offer.offerRequestDetails.map((detail) => {
          const sourceCountry = detail.itemDetails.country.name
          const deliveryCountry = offer.shopperDetails.deliveryTo.country

          return `${sourceCountry} → ${deliveryCountry}`
        })
      )
      const uniqueDestinations = Array.from(new Set(destinations))

      const offerWeights = offers.map((offer) =>
        offer.offerRequestDetails.reduce(
          (total, item) => total + convertToPounds(item.itemDetails.weight, item.itemDetails.weightUnit),
          0
        )
      )
      let minWeight = Math.ceil(Math.min(...offerWeights))
      const maxWeight = Math.ceil(Math.max(...offerWeights))

      if (minWeight === 0) {
        minWeight = 1
      }

      const offerPrices = offers.map((offer) =>
        offer.offerRequestDetails.reduce(
          (total, item) => total + +item.itemDetails.price * item.itemDetails.quantity,
          0
        )
      )
      const minPrice = Math.floor(Math.min(...offerPrices))
      const maxPrice = Math.ceil(Math.max(...offerPrices))

      const sections: FilterSectionConfig[] = [
        {
          id: 'destination',
          title: 'Destination',
          type: 'multi-select',
          options: [
            { primaryValue: 'All' },
            ...uniqueDestinations.map((destination) => ({ primaryValue: destination }))
          ],
          defaultValue: ['All'],
          filterDef: (item, filters) => {
            if (filters.destination && Array.isArray(filters.destination) && !filters.destination.includes('All')) {
              const sourceCountry = (item as OfferRequest).offerRequestDetails[0].itemDetails.country.name
              const deliveryCountry = (item as OfferRequest).shopperDetails.deliveryTo.country
              const itemDestination = `${sourceCountry} → ${deliveryCountry}`

              return filters.destination.includes(itemDestination)
            }

            return true
          }
        },
        {
          id: 'priceRange',
          title: 'Flight Discount',
          type: 'slider',
          options: [{ primaryValue: minPrice }, { primaryValue: maxPrice }],
          unit: { symbol: '$', label: 'USD' },
          defaultValue: [minPrice, maxPrice],
          filterDef: (item, filters) => {
            const [minPrice, maxPrice] = filters.priceRange as number[]
            const offerPrice = (item as OfferRequest).offerRequestDetails.reduce(
              (total, item) => total + +item.itemDetails.price * item.itemDetails.quantity,
              0
            )

            return offerPrice >= minPrice && offerPrice <= maxPrice
          }
        },
        {
          id: 'timePeriod',
          title: 'Time Period',
          type: 'multi-select',
          options: [
            { primaryValue: 'All' },
            { primaryValue: '1 Week' },
            { primaryValue: '2 Weeks' },
            { primaryValue: '3 Weeks' },
            { primaryValue: 'Anytime' }
          ],
          defaultValue: ['All'],
          filterDef: (item, filters) => {
            if (filters.timePeriod && Array.isArray(filters.timePeriod) && !filters.timePeriod.includes('All')) {
              const offerNeededByDate = (item as OfferRequest).neededByDate

              if (filters.timePeriod.includes('Anytime') && !offerNeededByDate) {
                //Anytime
                return true
              }

              const neededByDate = new Date(offerNeededByDate)

              const currentDate = new Date()
              const weeksDifference = Math.ceil(
                (neededByDate.getTime() - currentDate.getTime()) / (7 * 24 * 60 * 60 * 1000)
              )
              const selectedWeeks = filters.timePeriod.map((period: string) => parseInt(period.split(' ')[0]))

              return selectedWeeks.includes(weeksDifference)
            }

            return true
          }
        }
      ]

      if (minWeight !== maxWeight) {
        sections.splice(1, 0, {
          id: 'weight',
          title: 'Weight',
          type: 'slider',
          options: [{ primaryValue: minWeight }, { primaryValue: maxWeight }],
          unit: { symbol: '', label: 'lbs' },
          defaultValue: [minWeight, maxWeight],
          filterDef: (item, filters) => {
            const [minWeight, maxWeight] = filters.weight as number[]
            const offerWeight = (item as OfferRequest).offerRequestDetails.reduce((total, item) => {
              if (!item.itemDetails.weightUnit) {
                return total + 1
              }

              return total + convertToPounds(item.itemDetails.weight, item.itemDetails.weightUnit || 'lbs')
            }, 0)

            return offerWeight >= minWeight - 1 && offerWeight <= maxWeight
          }
        })
      }

      return sections
    },
    []
  )

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        title={{ text: 'Offers', id: 'Offers' }}
        subtitle="Select offers you want to carry and get a discount towards your flight"
        initialLoading={loading}
        fetchCall={fetchOffers}
        renderListData={OfferItemsView}
        emptyState={emptyState}
        layout="grid"
        verticalFilters={filterSections}
        searchBar={searchBar}
        gridSx={styles.listContainerGrid}
      />
    </Box>
  )
}

export default Offers
