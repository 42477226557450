import { Box, Chip, Typography } from '@mui/material'

import Accordion from '../../../../shared/components/Accordion/Accordion.component'
import Avatar from '../../../../shared/components/Avatar/Avatar.component'
import { EmptyState } from '../../../../shared/components/EmptyState/EmptyState.component'
import ItemRequestAccordion from '../ItemRequestAccordion/ItemRequestAccordion.component'

import { ItemRequest, OfferRequest } from '../../../../redux/states/offers/getOfferRequests/getOfferRequests.types'
import router from '../../../../router/functions/router.functions'
import routes from '../../../../router/routes.dictionary'
import { AvatarSize } from '../../../../shared/components/Avatar/Avatar.types'
import { PaginatedRenderProps } from '../../../../shared/components/PaginatedPage/PaginatedPage.types'
import date, { dateTimeFormats } from '../../../../shared/functions/Date/date.functions'
import styles from './OfferRequestsAccordion.styles'

const OfferRequestsAccordion = (props: PaginatedRenderProps) => {
  const offerRequest = props.item as unknown as OfferRequest
  const refreshData = props.refreshData
  const navigate = router.navigate()

  const refreshOfferRequests = (onSuccess?: (data?: unknown) => void) => {
    refreshData?.(onSuccess)
  }

  const getAccordionHeader = () => {
    const orderNumber = offerRequest.offerRequestId

    const accordionHeader = {
      mainContent: (
        <Typography variant="h6" sx={styles.accordionHeaderMainContent}>
          Order# {orderNumber}
        </Typography>
      )
    }

    return accordionHeader
  }

  const renderItemRequest = (itemRequest: ItemRequest) => (
    <ItemRequestAccordion
      itemRequest={itemRequest}
      deliveryDetails={offerRequest.deliveryDetails}
      refreshOfferRequests={refreshOfferRequests}
    />
  )

  const getArrivalDate = () => {
    const deliveryCity = offerRequest.deliveryDetails.city
    const flightItinerary = offerRequest.tripDetails.flights

    for (let i = flightItinerary.length - 1; i >= 0; i--) {
      const flight = flightItinerary[i]

      if (flight.destination?.city?.name === deliveryCity.name) {
        return date(flight.arrivalTime).format(dateTimeFormats.date.medium)
      }
    }

    return null
  }

  const renderDetails = () => {
    const hasItemRequests = offerRequest.itemRequests.length > 0
    const travelerName = `${offerRequest.tripDetails?.traveler.firstName} ${offerRequest.tripDetails?.traveler.lastName}`

    const shopperDetails = () => {
      if (offerRequest.tripDetails !== null) {
        return (
          <Box style={styles.shopperDetailsContainer}>
            <Avatar
              firstName={offerRequest.tripDetails.traveler.firstName}
              lastName={offerRequest.tripDetails.traveler.lastName}
              size={AvatarSize.small}
            />

            <Box style={styles.shopperDetailsTextContainer}>
              <Box sx={styles.shopperDetailsNameContainer}>
                <Typography sx={styles.shopperDetailsName}>{travelerName}</Typography>
                <Chip sx={styles.arrivalDateChip} label={`Arriving ${getArrivalDate()}`} />
              </Box>

              <Typography sx={styles.shopperDetailsTag}>Hourrier</Typography>
            </Box>
          </Box>
        )
      }
    }

    if (!hasItemRequests) {
      return (
        <Box style={styles.emptyStateContainer}>
          <EmptyState
            title={'You have no orders available'}
            button={{
              text: 'Shop Now',
              onClick: () => navigate(routes.shop.path)
            }}
          />
        </Box>
      )
    } else {
      return (
        <Box>
          {offerRequest.itemRequests.map(renderItemRequest)}
          {shopperDetails()}
        </Box>
      )
    }
  }

  return (
    <Accordion
      header={getAccordionHeader()}
      body={renderDetails()}
      noExpandIcon
      noExpandBackgroundColor
      isExpanded
      preventExpandChange
    />
  )
}

export default OfferRequestsAccordion
