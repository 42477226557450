import React, { useCallback, useEffect } from 'react'

import { Box } from '@mui/material'

import PaginatedPage from '../../shared/components/PaginatedPage/PaginatedPage.component'
import FlightItineraryAccordion from './components/FlightItineraryAccordion/FlightItineraryAccordion.component'

import { getAllFlightItineraries } from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.slice'
import {
  FlightItinerary,
  GetAllFlightItinerariesRequest
} from '../../redux/states/flights/getAllFlightItineraries/getAllFlightItineraries.types'
import { RootState } from '../../redux/store/store.types'
import router from '../../router/functions/router.functions'
import routes from '../../router/routes.dictionary'
import { EmptyStateProps } from '../../shared/components/EmptyState/EmptyState.types'
import { FilterTab, PaginatedResponse, SearchBarProps } from '../../shared/components/PaginatedPage/PaginatedPage.types'
import {
  FilterSectionConfig,
  Filters
} from '../../shared/components/UniversalFilterSideBar/UniversalFilterSideBar.types'
import status from '../../shared/dictionaries/status.dictionaries'
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux.hooks'
import { convertToPounds } from '../Offers/Offers.functions'
import styles from './Requests.styles'

const Requests: React.FC = () => {
  const { loading } = useAppSelector((state: RootState) => state.getAllFlightItineraries)
  const dispatch = useAppDispatch()
  const navigate = router.navigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const fetchFlightItineraries = useCallback(
    async (page: number): Promise<PaginatedResponse<FlightItinerary>> => {
      return new Promise((resolve) => {
        const getAllFlightItinerariesPayload: GetAllFlightItinerariesRequest = {
          page,
          size: 20
        }

        dispatch(
          getAllFlightItineraries({
            request: getAllFlightItinerariesPayload,
            onSuccess: (response) => {
              resolve({
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                results: response.data.results
              })
            }
          })
        )
      })
    },
    [dispatch]
  )

  const filterTabs: FilterTab<unknown>[] = [
    {
      id: 'all',
      label: 'All',
      filterDef: (items: unknown[]) => items as FlightItinerary[]
    },

    {
      id: 'accepted',
      label: 'Accepted',
      filterDef: (items: unknown[]) => {
        return (items as FlightItinerary[]).filter((item) =>
          item.itinerary.legs.some((leg) =>
            leg.offerRequestsDetails.some((offerRequest) =>
              offerRequest.itemRequestDetails.some(
                (detail) => detail.status.name === status.itemRequestStatus.acceptedByTraveler
              )
            )
          )
        )
      }
    },

    {
      id: 'pending',
      label: 'Pending',
      filterDef: (items: unknown[]) =>
        (items as FlightItinerary[]).filter((item) =>
          item.itinerary.legs.some((leg) =>
            leg.offerRequestsDetails.some((offerRequest) =>
              offerRequest.itemRequestDetails.some(
                (detail) => detail.status.name === status.itemRequestStatus.pendingAcceptanceByTraveler
              )
            )
          )
        )
    }

    // Todo: For Item tracking endpoint
    // {
    //   id: 'delivered',
    //   label: 'Delivered',
    //   filterDef: (items: unknown[]) =>
    //     (items as FlightBooking[]).filter((item) =>
    //       item.flightLegs.some((leg) =>
    //         leg.offerRequestsDetails.some((offerRequest) =>
    //           offerRequest.itemRequestDetails.some((detail) => detail.status.name === 'delivered')
    //         )
    //       )
    //     )
    // }
  ]

  const searchBar: SearchBarProps = {
    placeholder: 'Type any destination or item name',
    onSearchFilter: (items, query) => {
      const lowerQuery = query.toLowerCase()

      return (items as FlightItinerary[]).filter((flightItinerary) =>
        flightItinerary.itinerary.legs.some(
          (leg) =>
            leg.destination.airport.name.toLowerCase().includes(lowerQuery) ||
            leg.destination.airport.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.destination.city.name.toLowerCase().includes(lowerQuery) ||
            leg.destination.city.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.destination.country.name.toLowerCase().includes(lowerQuery) ||
            leg.destination.country.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.origin.airport.name.toLowerCase().includes(lowerQuery) ||
            leg.origin.airport.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.origin.city.name.toLowerCase().includes(lowerQuery) ||
            leg.origin.city.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.origin.country.name.toLowerCase().includes(lowerQuery) ||
            leg.origin.country.iataCode.toLowerCase().includes(lowerQuery) ||
            leg.offerRequestsDetails.some((offerRequest) =>
              offerRequest.itemRequestDetails.some(
                (detail) =>
                  detail.item.productName.toLowerCase().includes(lowerQuery) ||
                  detail.item.description.toLowerCase().includes(lowerQuery) ||
                  detail.item.name.toLowerCase().includes(lowerQuery)
              )
            )
        )
      )
    }
  }

  const emptyState: EmptyStateProps = {
    title: 'You have no trips available',
    subtitle: 'Book a trip and share your link with friends and family to get started.',
    button: {
      text: 'Book Trip',
      onClick: () => {
        navigate(routes.home.path)
      }
    }
  }

  const filterSections: (items: unknown[], filters: Filters) => FilterSectionConfig[] = useCallback(
    (items: unknown[]) => {
      const flightItineraries = items as FlightItinerary[]

      // Extract unique shoppers
      const shoppers = Array.from(
        new Set(
          flightItineraries.flatMap((itinerary) =>
            itinerary.itinerary.legs.flatMap((leg) =>
              leg.offerRequestsDetails.map(
                (offerRequest) => `${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName}`
              )
            )
          )
        )
      )

      // Extract weights and prices
      const weights = flightItineraries.flatMap((itinerary) =>
        itinerary.itinerary.legs.flatMap((leg) =>
          leg.offerRequestsDetails.flatMap((offerRequest) =>
            offerRequest.itemRequestDetails.map((detail) => convertToPounds(detail.item.weight, detail.item.weightUnit))
          )
        )
      )

      let minWeight = Math.floor(Math.min(...weights))
      const maxWeight = Math.ceil(Math.max(...weights))

      if (minWeight <= 0) {
        minWeight = 1
      }

      const prices = flightItineraries.flatMap((itinerary) =>
        itinerary.itinerary.legs.flatMap((leg) =>
          leg.offerRequestsDetails.flatMap((offerRequest) =>
            offerRequest.itemRequestDetails.map((detail) => +detail.item.price)
          )
        )
      )

      const minPrice = Math.min(...prices)
      const maxPrice = Math.max(...prices)

      const sections: FilterSectionConfig[] = [
        {
          id: 'shopper',
          title: 'Shopper',
          type: 'multi-select',
          options: [{ primaryValue: 'All' }, ...shoppers.map((shopper) => ({ primaryValue: shopper }))],
          defaultValue: ['All'],
          filterDef: (item, filters) => {
            if (filters.shopper && Array.isArray(filters.shopper) && !filters.shopper.includes('All')) {
              return (item as FlightItinerary).itinerary.legs.some((leg) =>
                leg.offerRequestsDetails.some((offerRequest) => {
                  return (filters.shopper as string[]).includes(
                    `${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName}`
                  )
                })
              )
            }

            return true
          },
          forceShow: (item) => {
            return (item as FlightItinerary).itinerary.legs.some((leg) =>
              leg.offerRequestsDetails.every((offerRequest) => offerRequest.itemRequestDetails.length === 0)
            )
          }
        },
        {
          id: 'weight',
          title: 'Item Weight',
          type: 'slider',
          options: [{ primaryValue: minWeight }, { primaryValue: maxWeight }],
          unit: { symbol: '', label: 'lbs' },
          defaultValue: [minWeight, maxWeight],
          filterDef: (item, filters) => {
            const [minWeight, maxWeight] = filters.weight as number[]

            return (item as FlightItinerary).itinerary.legs.some((leg) =>
              leg.offerRequestsDetails.some((offerRequest) =>
                offerRequest.itemRequestDetails.some((detail) => {
                  let itemWeight = convertToPounds(detail.item.weight, detail.item.weightUnit)
                  if (itemWeight < 1) {
                    itemWeight = 1
                  }

                  return itemWeight >= minWeight && itemWeight <= maxWeight
                })
              )
            )
          },
          forceShow: (item) => {
            return (item as FlightItinerary).itinerary.legs.some((leg) =>
              leg.offerRequestsDetails.every((offerRequest) => offerRequest.itemRequestDetails.length === 0)
            )
          }
        },
        {
          id: 'priceRange',
          title: 'Flight Discount',
          type: 'slider',
          options: [{ primaryValue: minPrice }, { primaryValue: maxPrice }],
          unit: { symbol: '$', label: 'USD' },
          defaultValue: [minPrice, maxPrice],
          filterDef: (item, filters) => {
            const [minPrice, maxPrice] = filters.priceRange as number[]

            return (item as FlightItinerary).itinerary.legs.some((leg) =>
              leg.offerRequestsDetails.some((offerRequest) =>
                offerRequest.itemRequestDetails.some(
                  (detail) => +detail.item.price >= minPrice && +detail.item.price <= maxPrice
                )
              )
            )
          },
          forceShow: (item) => {
            return (item as FlightItinerary).itinerary.legs.some((leg) =>
              leg.offerRequestsDetails.every((offerRequest) => offerRequest.itemRequestDetails.length === 0)
            )
          }
        }
      ]

      return sections
    },
    []
  )

  const hideAllFilters = (items: unknown[]) => {
    const flightItineraries = items as FlightItinerary[]

    return flightItineraries.every((itinerary) =>
      itinerary.itinerary.legs.every((leg) => leg.offerRequestsDetails.length === 0)
    )
  }

  // const renderFilteredList = (items: unknown[], filters: Filters) => {
  //   const flightBookings = items as FlightBooking[]

  //   return flightBookings.map((booking) => {
  //     const filteredLegs = booking.flightLegs.map((leg) => {
  //       const filteredOfferRequests = leg.offerRequestsDetails.filter((offerRequest) =>
  //         filters.shopper && Array.isArray(filters.shopper) && !filters.shopper.includes('All')
  //           ? (filters.shopper as string[]).includes(
  //               `${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName}`
  //             )
  //           : true
  //       )

  //       return { ...leg, offerRequestsDetails: filteredOfferRequests }
  //     })

  //     return { ...booking, flightLegs: filteredLegs }
  //   })
  // }

  const filterMutateDef = (itemsToFilter: unknown[], filters: Filters): FlightItinerary[] => {
    const items = itemsToFilter as FlightItinerary[]

    return items.map((itinerary) => ({
      ...itinerary,
      itinerary: {
        ...itinerary.itinerary,
        legs: itinerary.itinerary.legs.map((leg) => ({
          ...leg,
          offerRequestsDetails: leg.offerRequestsDetails
            .filter((offerRequest) => {
              const shopperFilter =
                filters.shopper && Array.isArray(filters.shopper) && !filters.shopper.includes('All')
                  ? (filters.shopper as string[]).includes(
                      `${offerRequest.shopperDetails.firstName} ${offerRequest.shopperDetails.lastName}`
                    )
                  : true

              return shopperFilter
            })
            .map((offerRequest) => ({
              ...offerRequest,
              itemRequestDetails: offerRequest.itemRequestDetails.filter((detail) => {
                let weightFilter = true
                if (filters.weight && Array.isArray(filters.weight)) {
                  const [minWeight, maxWeight] = filters.weight as number[]
                  let itemWeight = convertToPounds(detail.item.weight, detail.item.weightUnit)
                  if (itemWeight < 1) {
                    itemWeight = 1
                  }
                  weightFilter = itemWeight >= (minWeight < 1 ? 1 : minWeight) && itemWeight <= maxWeight
                }

                let priceFilter = true
                if (filters.priceRange && Array.isArray(filters.priceRange)) {
                  const [minPrice, maxPrice] = filters.priceRange as number[]
                  const itemPrice = +detail.item.price
                  priceFilter = itemPrice >= minPrice && itemPrice <= maxPrice
                }

                return weightFilter && priceFilter
              })
            }))
        }))
      }
    }))
  }

  return (
    <Box sx={styles.container}>
      <PaginatedPage
        title={{ text: 'Requests', id: 'Requests' }}
        subtitle="Select offers you want to carry and get a discount towards your flight."
        initialLoading={loading}
        emptyState={emptyState}
        hideFilters={hideAllFilters}
        filterMutateDef={filterMutateDef}
        fetchCall={fetchFlightItineraries}
        renderListData={FlightItineraryAccordion}
        horizontalFilterTabs={filterTabs}
        verticalFilters={filterSections}
        searchBar={searchBar}
      />
    </Box>
  )
}

export default Requests
